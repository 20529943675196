@import "../../Variables";

.profile{
	
	.message-card{
		margin-left: 0;
	}

}


.token-description-edit-link.btn{
  padding-left: 0;
  padding-right: 0;
}