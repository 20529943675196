@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) { }
@include media-breakpoint-up(md) { }
@include media-breakpoint-up(lg) { }
@include media-breakpoint-up(xl) { }

.breakpoints{
	width: 100%;
	height: 50px;
	color: white;
	font-weight: bold;
	text-align: center;
	line-height: 50px;
	font-size: 1.2rem;


	@include media-breakpoint-up(sm) { 
		background-color: red;
		&::after {
		  content: "sm-and-up";
		}
	}
	@include media-breakpoint-up(md) { 
		background-color: orange;
		&::after {
		  content: "md-and-up";
		}
	}
	@include media-breakpoint-up(lg) { 
		background-color: green;
		&::after {
		  content: "lg-and-up";
		}
	}
	@include media-breakpoint-up(xl) { 
		background-color: blue;
		&::after {
		  content: "xl-and-up";
		}
	}

}