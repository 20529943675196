//import variables
@import "../../../Variables";


.MuiSlider-valueLabel{
  z-index: 999999 !important;
}

.asset-table{

  .staking-row{
    background-color: yellow;
  }

  .profile-image {
    width: 30px;
    height: 30px;
  }

  .table-header{
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.7rem;
    color: #AFAFAF;
    height: 2.5rem;
  }

  span.rank{
   font-size: 0.8rem;
   color: #999;

   .staking-row{
    font-weight: bold;
   }

 }

 .asset-search{
  position: relative;

  i {
    position: absolute;
    left: 5px;
    line-height: 25px;
    font-size: 0.7rem;
    color: #999;
  }
  
  input {
    border: 0;
    display: block;
    padding: 3px;
    padding-left: 20px;
    font-size: 12px;
    width: 80%;
    border-radius: 25px;
    border: 1px solid #ddd;
  }
}

.asset-row{
  font-size: 0.8rem;
  padding: 0.4rem 0;
  color: #444;
  background-color: $twentyonemuted;
  border-bottom: 1px solid #eee;

  .my-stake{

  }

  .total-stakes{

  }

  &:hover{
    background-color: #fff;

    .my-stake{
      bottom: 0;
      padding: 0 10px;
      border-radius: 3px;
      font-weight: bold;
      cursor: pointer;
      background-color: #000;
      color: white;

      &:after{
        content: ' edit'
      }
      &:hover{
      }
        transition: all 0.1s linear;

    }
    .total-stakes{
      display: none;
    }
  }
  
  &.staking-row{
    background-color: #fff;
    font-weight: bold;
  }

  &.editing{
    cursor: default;
  }
  &.rank-changed-up{
    background-color: #E7FFE7;
  }
  &.rank-changed-down{
    background-color: #FFE4E1;
  }
  .fa-edit {
    display: none;
    cursor: pointer;
  }
  .close-allocator {
    cursor: pointer;
  }


  &.allocating {
    .my-stake {
      cursor: default;
    }
    &:hover{
      .fa-edit {
        display: none;
      }
    }
  }

  &.selected{
    background-color: #F3F6F6;
    color: #000;

  }
}
}

.stake-arrow {
 position: absolute;
 left: -1.5em;
 top: 0.2em;
}
