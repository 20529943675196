//import variables
@import "Variables";

//then override bootstrap variables
$theme-colors: (
	"primary": $twentyoneprimary
	);

//then import Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

//then breakpoint bootstrap libs
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@import url('https://rsms.me/inter/inter.css');

html{
	height: 100%;
}

body{
	height: 100%;
	font-size: 1rem;
	font-family: 'Noto Serif JP', serif !important;
	background-color: $twentyonemuted;
	::-webkit-scrollbar {
		display: none;
	}
   	scrollbar-width: none
}

hr {
	border: none;
	height: 2px;
	background-color: $twentyonemuted;
}

.container-fluid{
	height: 100%;
	padding: 0;
	margin: 0;
}

.modal-backdrop{
	background-color: rgba(0,0,0,0.7);
}

.followme, .discover, .nav-column{

	@include media-breakpoint-up(md) {
		height: 100vh;
		overflow-y: scroll;
	}

}

.nav-column{
	background-color: white;
  	border-radius: 3px;
  	overflow-x: hidden;
}

.followme{
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;
}

.enough-to-decode {
	font-family: "Inter UI";
	font-weight: 300;
	font-size: 14px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.badge {
	font-family: "Inter UI", sans-serif;
	font-weight: 500;
	border: solid 1px #ddd;
	background-color: white;
	margin-right: 3px;
	i{
		font-size: 10px;
	}

	&.span{
		display: inline-block;
	}
}

.token-name{
	font-weight: bold;

	&::before{
		content: "$";
	}

	&.large{
		font-size: 1.2rem;
	}

	&.medium{
		font-size: 1rem;
	}

}

iframe{
	max-width: 100%;
}

.card{
	border: none !important;
}

table.table-borderless tr{
	border-bottom: 1px solid #eee;
}

.hidden-char{
	&.0{
		color: red !important;

	}
}

.fa-coins{
	color: #F6B14A;
}


.screen-size-xs{
	&.page-name-profile{
		.discover {
			display: none;
		}
	}
}