//import variables
@import "../../Variables";

.vertical-nav{
	font-size: 1rem;
	.nav-item.dropdown > .nav-link.in.dropdown-toggle {
		font-weight: 600;
		font-size: 1.1rem;
		color: #000;
		text-transform: unset !important;
		span.in {
			position: relative;
			top: 2px;
		}
	}
}

.balance {
	font-family: "Inter", sans-serif;
	font-weight: 500;
	font-size: 0.7rem !important;
	position: relative;
	top: -25px;
}

.logo{
	max-width: 80px;
	max-height: 80px;
}

.nav-link {
	font-family: "Inter", sans-serif !important;
	font-size: 0.8rem;
	text-transform: uppercase;
	font-weight: 400;
	color: #ADADAD;
	letter-spacing: 0.3px;
	padding: 1rem !important;
	transition: all 0.2s ease-in-out;
}

.nav-link.active {
	font-weight: bold;
	color: #000;
	border-bottom: 1px solid #000;
}

.fm-nav{
	border-bottom: 1px solid #eee;
	background-color: white;
	display: flex;
	flex-flow: row wrap;
	align-content: center;
	justify-content: flex-start;

		.compose-button{
			font-family: "Inter", sans-serif;
			margin-left: auto;
			font-size: 0.8rem;
			text-transform: uppercase;
			font-weight: 600;
			padding: 1rem !important;
			color: black;
			transition: all 0.3s ease-in-out;
			text-align: center;
			i {
				margin-right: 5px;
			}

			&:hover{
				cursor: pointer;
				color: white;
				background-color: black;
			}

		}
	
	.decodable-posts {
		margin-left: 2px;
		position: relative;
		top: -0.5px;
		color: #db7288;
		font-size: 0.7rem;
		font-weight: 500;
	}

}

ul.nav{
	.nav-item .nav-link{
		padding: 0.25rem 1rem;
	}
	margin-bottom: 1.2rem;
}