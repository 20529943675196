//import variables
@import "../../Variables";

/* message compose styles */

.follow-me-feed{
  .pagination {
    margin-top: 0.5rem;
    // margin-bottom: 0.5rem;
    &.no-pages {
      display: none;
    }
  }

  .prev, .next {
    padding: 1rem;

    &.disabled{
      cursor: default;
      opacity: 0.5;
      text-decoration: none;
    }
  }
}

.hidden-char{
  font-size: 1.5rem;
  font-family: "Redacted";
  color: #eee;

}

.message-copy-url{
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
}
