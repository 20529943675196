@import 'src/Variables.scss';

$dot0: lighten($twentyoneprimary,20%);
$dot1: lighten($twentyoneprimary,20%);
$dot2: lighten($twentyoneprimary,20%);
$dot3: lighten($twentyoneprimary,20%);
$dot4: lighten($twentyoneprimary,20%);
$dot5: lighten($twentyoneprimary,20%);
$dot6: lighten($twentyoneprimary,20%);
$dot7: lighten($twentyoneprimary,20%);
$dot8: lighten($twentyoneprimary,20%);
$dot9: lighten($twentyoneprimary,20%);


%action-dot{
    border:1px solid transparent;
}

%disabled{
    cursor: default;
}

.per-block{
    font-size: 0.6rem;
}

.dots{
    min-width: 8rem;
}

.dots span{
    cursor: pointer;
    height: 1rem;
    width: 0.5rem;
    display: inline-block;
    margin-right: 3px;
    background-color: #aaa;

    &.disabled{
        @extend %disabled;
    }
}


.dot0{
    &.active{
        border-color: $dot0;
        background-color: $dot0;
    }
}
.dot1{
    @extend %action-dot;
    &.active{
        background-color: $dot1;
        border-color: $dot1;
    }
}

.dot2 {
    @extend %action-dot;
    &.active{
        background-color: $dot2;
        border-color: $dot2;
    }
}

.dot3 {
    @extend %action-dot;
    &.active{
        background-color: $dot3;
        border-color: $dot3;
    }
}

.dot4 {
    @extend %action-dot;
    &.active{
        background-color: $dot4;
        border-color: $dot4;
    }
}

.dot5 {
    @extend %action-dot;
    &.active{
        background-color: $dot5;
        border-color: $dot5;
    }
}

.dot6 {
    @extend %action-dot;
    &.active{
        background-color: $dot6;
        border-color: $dot6;
    }
}

.dot7 {
    @extend %action-dot;
    &.active{
        background-color: $dot7;
        border-color: $dot7;
    }
}

.dot8 {
    @extend %action-dot;
    &.active{
        background-color: $dot8;
        border-color: $dot8;
    }
}

.dot9 {
    @extend %action-dot;
    &.active{
        background-color: $dot9;
        border-color: $dot9;
    }
}
