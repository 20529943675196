  .profile-image{
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    
  	&.pulse{
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      animation: pulse 2s infinite;
      @keyframes pulse {
        0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
      }
    }
  }