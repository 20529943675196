
.holder-icon{
	width: 15px;
	height: 15px !important;
  margin-right: 2px;
}

.holder-icon-1 {
  z-index: 2;
}

.holder-icon-2 {
  z-index: 1;
}

.holder-icon-3 {
  z-index: 0;
}

// span needs less margin if only 2 profile images
.holder-icon-1 + span{
  margin-left: 0.5rem;
}

// span needs less margin if only 2 profile images
.holder-icon-2 + span{
  margin-left: 0.4rem;
}

.holder-icon-3 + span{
  margin-left: 0.3rem;
}

.holder-icon.jazzicon {
  display: inline-block;
  position: relative;
  top: 2px;
}