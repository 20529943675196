@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(md) { }
@include media-breakpoint-up(lg) { }
@include media-breakpoint-up(xl) { }


.user-data-feed{
	display: none !important;
}

@include media-breakpoint-up(md) { 
	.user-data-feed{
		display: block !important;
	}
}


.asset-holdings{
  font-size: 0.75rem;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;

  .profile-image{
  }

}

.progress{
  height: 5px !important;
}